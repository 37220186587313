import React from 'react';

const About = () => {

  return (
    <section>
      <h1 className="title">Im About page</h1>
    </section>
  )
}
export default About;